<template>
  <div>
    <draggable
      v-model="iitems"
      :animation="100"
      tag="ol"
      role="listbox"
    >
      <li
        v-for="(item, index) in iitems"
        :key="index"
        role="option"
        draggable="true"
        tabindex="0"
        class="list-item"
      >
        <p v-text="item" />
      </li>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'DraggableList',
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    'draggable': draggable
  },
  data () {
    return {
      iitems: JSON.parse(JSON.stringify(this.items))
    }
  },
  watch: {
    iitems: function () {
      this.$emit('update:items', this.iitems)
    },
    items: function () {
      this.iitems = this.items
    }
  }
}
</script>

<style>
ol, ul {
  list-style: none;
}
.list-item {
  margin: 10px;
  padding: 10px;
  border: 1px solid #dfdfdf;
  background: #f5f5f5;
}
</style>
